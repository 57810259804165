import React from "react";
import { WarrantyRepairMobile } from "../../components/warrantyrepair/";

const WarrantyRepairPage = () => {
  return (
    <div>
      <WarrantyRepairMobile />
    </div>
  );
};

export { WarrantyRepairPage };
