import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/layout";
import { WarrantyRepairPage } from "../modules/warrantyrepairpage";
import "../styles/1.reset.scss";
import "../styles/2.main.scss";

export default () => {
  return (
    <div>
      <Helmet>
        <title>Ремонт iPhone</title>
      </Helmet>
      <Layout>
        <WarrantyRepairPage />
      </Layout>
    </div>
  );
};
